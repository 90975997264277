<template>
  <div id="Trivsel">
    <v-container style="max-width: 1168px">
      <h3>{{ $t('comfort.first-thing') }}</h3>
      <h3 class="my-3">{{ $t('comfort.heat') }}</h3>
      <ol>
        <li>
          {{ $t('comfort.big-bathroom') }}
        </li>
        <li>
          {{ $t('comfort.small-bathroom') }}
        </li>
        <li>
          {{ $t('comfort.turn-on') }}
        </li>
      </ol>
      <p>
        {{ $t('comfort.ac') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.wifi-title') }}</h3>
      <p>
        {{ $t('comfort.wifi') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.garbage-title') }}</h3>
      <p>
        {{ $t('comfort.garbage') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.dishwasher-title') }}</h3>
      <p>
        {{ $t('comfort.dishwasher') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.laundry-title') }}</h3>
      <p>
        {{ $t('comfort.laundry') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.fireplace-title') }}</h3>
      <p>
        {{ $t('comfort.fireplace') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.ev-title') }}</h3>
      <p>
        {{ $t('comfort.ev') }}
      </p>

      <h3 class="my-3">{{ $t('comfort.private-title') }}</h3>
      <p>
        {{ $t('comfort.private-things') }}
      </p>
      <ul>
        <li>
          {{ $t('comfort.private.1') }}
        </li>
        <li>
          {{ $t('comfort.private.2') }}
        </li>
        <li>
          {{ $t('comfort.private.3') }}
        </li>
      </ul>
      <v-divider class="my-3"></v-divider>
      <h2>{{ $t('comfort.leave-title') }}</h2>
      <p>
        {{ $t('comfort.leave') }}
      </p>
      <h4>{{ $t('comfort.turn-off-title') }}</h4>
      <ul>
        <li>
          {{ $t('comfort.turn-off.1') }}
        </li>
        <li>{{ $t('comfort.turn-off.2') }}</li>
        <li>{{ $t('comfort.turn-off.3') }}</li>
        <li>{{ $t('comfort.turn-off.4') }}</li>
        <li>{{ $t('comfort.turn-off.5') }}</li>
        <li>{{ $t('comfort.turn-off.6') }}</li>
        <li>{{ $t('comfort.turn-off.7') }}</li>
        <li>{{ $t('comfort.turn-off.8') }}</li>
        <li>{{ $t('comfort.turn-off.9') }}</li>
        <li>{{ $t('comfort.turn-off.10') }}</li>
      </ul>

      <h3 class="my-3">{{ $t('comfort.cleaning-title') }}</h3>
      <p>
        {{ $t('comfort.cleaning-intro') }}
      </p>

      <ul>
        <li>
          {{ $t('comfort.cleaning.1') }}
        </li>
        <li>{{ $t('comfort.cleaning.2') }}</li>
        <li>
          {{ $t('comfort.cleaning.3') }}
        </li>
        <li>{{ $t('comfort.cleaning.4') }}</li>
        <li>
          {{ $t('comfort.cleaning.5') }}
        </li>
        <li>{{ $t('comfort.cleaning.6') }}</li>
        <li>{{ $t('comfort.cleaning.7') }}</li>
        <li>{{ $t('comfort.cleaning.8') }}</li>
      </ul>

      <h3 class="my-3">{{ $t('comfort.breaks-title') }}</h3>
      <p>
        {{ $t('comfort.breaks') }}
      </p>

      <p>
        {{ $t('comfort.feedback') }}
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Hem',
    meta: [
      { name: 'description', content: 'Majostugan, en mysig plats i Ljusnedal och Funäsdalen' },
    ],
  },
};
</script>

<style></style>
